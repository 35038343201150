import React from 'react'

export default function MainSkills() {
    return (
        <div className='main-skills' id='main-skills'>
            <article>
                <i className="fa-solid fa-compass-drafting"></i>
                <h3>Web Design</h3>
                <p>
                    Over the past few years,
                    I have grown to love designing websites.
                    I enjoy making a wireframe and developing
                    on that to create a visually beautiful page.
                </p>
            </article>
            <article>
                <i className="fa-solid fa-crop"></i>
                <h3>Responsiveness</h3>
                <p>
                    No website is complete without being responsive.
                    I practice with the best and simplest ways to make
                    my websites responsive.
                </p>
            </article>
            <article>
                <i className="fa-solid fa-screwdriver-wrench"></i>
                <h3>Passion</h3>
                <p>
                    Above all, I have grown to love coding. I love
                    HTML, CSS, and JavaScript, however creating
                    a backend to support my sites is very fulfilling
                    as well.
                </p>
            </article>
        </div>
    )
}
