import React from 'react'
import { Link } from 'react-router-dom'
// import personalLogo from "../images/personal-logo.png"
import MenuIcon from '@mui/icons-material/Menu';
interface PropTypes {
    sidebarOpen: boolean
    setSidebarOpen: any
    goHome?: boolean
}

export default function Navbar({sidebarOpen, setSidebarOpen, goHome=false}: PropTypes) {

    return (
        <nav className='main-navbar'>
            {!goHome && <MenuIcon
                className={`menu-icon`}
                onClick={() => setSidebarOpen(!sidebarOpen)}
            />}
            {/*<div>*/}
            {/*    {!goHome ? <h1><Link to='/contact'>Contact Me</Link></h1>: <h1><Link to='/'>Home</Link></h1>}*/}
            {/*</div>*/}
        </nav>
    )
}
