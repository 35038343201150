import React from 'react'

export default function Projects() {
    return (
        <main className='projects' id='projects'>
            <h2>Previous Projects</h2>
            <section className='flex-container'>
                <article>
                    <div>
                        <h3>Movie Database</h3>
                        <p>
                            This site uses the Movie Database to provide
                            up to date information on movies. It is built
                            with React and Sass, making API calls to keep
                            the information relevant.
                        </p>
                        <button>
                            <a target='_blank' rel={`noreferrer`} href="https://moviedb-fqjw1twex-blakepow.vercel.app/">View Project</a>
                        </button>
                    </div>
                    <img src={require('../images/cinema.jpeg')} alt=""/>
                </article>
                <article>
                    <img src={require('../images/weather.jpeg')} alt=""/>
                    <div>
                        <h3>Weather App</h3>
                        <p>
                            This was built with the basics,
                            vanilla JavaScript, CSS, and plain HTML.
                            This displays weather from Idaho, keeping it
                            up to date whenever you need an update on Idaho!
                        </p>
                        <button>
                            <a target='_blank' rel={`noreferrer`} href="https://blakepow.github.io/wdd230/lesson11/index.html">View Project</a>
                        </button>
                    </div>
                </article>
                <article>
                    <div>
                        <h3>Scrumptious Recipes</h3>
                        <p>
                            A work in progress using React, Sass, and Firebase
                            to create a recipe sharing site. Users can create
                            accounts, add recipes, and share them with others.
                        </p>
                        <button>
                            <a target='_blank' rel={`noreferrer`} href="https://scrumptioushub.com">View Project</a>
                        </button>
                    </div>
                    <img src={require('../images/blog.jpeg')} alt=""/>
                </article>
            </section>
        </main>
    )
}
