import React from 'react'
import personalPic from "../images/portfolio-pic.png"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function GrowthSection() {
    return (
        <main className='growth-section' id="growth-section">
            <section className="grid-container">
                <div>
                    <img className='personal-pic' src={personalPic} alt=""/>
                </div>
                <article>
                    <h1>Hello!</h1>
                    <p>
                        My name is Blake Powelson, I'm a Web Developer and
                        here is some of my work!
                    </p>
                    <button>
                        <a href="#main-skills">
                            Learn More
                        </a>
                    </button>
                    {/* <ul>
                        <li>HTML</li>
                        <li>CSS/SCSS</li>
                        <li>JavaScript</li>
                        <li>React</li>
                    </ul> */}
                </article>
            </section>
            <a href="#main-skills">
                <KeyboardArrowDownIcon className={`arrow-down-icon`} />
            </a>
        </main>
    )
}
