import React from 'react'

interface PropTypes {
    sidebarOpen: boolean
    setSidebarOpen: any
}

export default function Sidebar({sidebarOpen, setSidebarOpen}: PropTypes) {

    //close sidebar when screen is resized
    window.addEventListener('resize', () => {
        if (window.innerWidth > 768) {
            setSidebarOpen(false)
        }
    })



    return (
        <aside className={`main-aside ${sidebarOpen ? 'open' : 'closed'}`}>
            <div>
                    <a href="#growth-section">Intro</a>
                    <a href="#main-skills">Skills</a>
                    <a href="#projects">Projects</a>
                    <a href="#footer">Socials</a>
            </div>
        </aside>
    )
}
