import React from 'react'

export default function Contact() {
    return (
        <main className='contact-page'>
            <img src={require('../images/disney.jpg')} alt="Disney Trip"/>
            <form action="mailto:blakepow@gmail.com" method="post" encType="text/plain">
                <h1>Contact</h1>
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id='name' autoComplete='off' />
                <label htmlFor="email">Email</label>
                <input type="text" name="mail" id='email' autoComplete='off' />
                <label htmlFor="comment">Comment</label>
                <textarea name="comment" id='comment' autoComplete='off' />
                <div>
                    <button type="reset" value="Reset">Clear Form</button>
                    <button type="submit" value="Send">Send</button>
                </div>
            </form>
        </main>
    )
}
