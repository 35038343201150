import React from 'react'

import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Footer() {
    return (
        <footer className='main-footer' id='footer'>
            <div>
                <a href="https://github.com/blakepow" target='_blank' rel={`noreferrer`}>
                    <GitHubIcon className={`icon`}/>
                </a>
                <a href="https://twitter.com/_blakepow" target='_blank' rel={`noreferrer`}>
                    <TwitterIcon className={`icon`}/>
                </a>
                <a href="https://www.facebook.com/blake.powelson.9" target='_blank' rel={`noreferrer`}>
                    <FacebookIcon className={`icon`}/>
                </a>
                <a href="https://www.instagram.com/blakey_da_snakey/" target='_blank' rel={`noreferrer`}>
                    {/*<i className="fa-brands fa-instagram"></i>*/}
                    <InstagramIcon className={`icon`}/>
                </a>
                <a href="https://www.linkedin.com/in/blakepowelson/" target='_blank' rel={`noreferrer`}>
                    {/*<i className="fa-brands fa-linkedin"></i>*/}
                    <LinkedInIcon className={`icon`}/>
                </a>
            </div>
        </footer>
    )
}
