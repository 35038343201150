import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import GrowthSection from './components/GrowthSection';
import MainSkills from './components/MainSkills';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Sidebar from './components/Sidebar';
import Contact from './pages/Contact';
import './scss/styles.css'

function App() {

  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <main className='App'>
      <Routes>
        <Route
          path='/' 
          element={
            <>
              <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
              <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}  />
              <main className='content'>
                <GrowthSection />
                <MainSkills />
                <Projects />
                <Footer />
              </main>
            </>
          } 
        />
        <Route 
          path='/contact' 
          element={
            <>
              <Navbar goHome sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
              <Contact />
            </>
          } 
        />
      </Routes>
    </main>
  );
}

export default App;
